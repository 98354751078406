import "./CategorySelection.scss";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";

import { ListItemCategoryInternalModel } from "../../services/internalStorage/models/ListItemCategoryInternalModel";
import { ListItemInternalModel } from "../../services/internalStorage/models/ListItemInternalModel";
import { getDateValue } from "../../utils/dateTimeUtil";
import normalizeText from "../../utils/normalizeText";
import { BaseInput } from "../BaseInput/BaseInput";
import { CategoriesList } from "../CategoriesList/CategoriesList";
import { CurtainPopupHeading } from "../CurtainPopupHeading/CurtainPopupHeading";

export interface CategorySelectionProps {
  categories: ListItemCategoryInternalModel[];
  currentListItem?: ListItemInternalModel;
  onSelect: (category: ListItemCategoryInternalModel) => void;
  onCancel: () => void;
  reset: boolean;
  onCreateCategoryClick: () => void;
  onEditCategoryClick: (categoryToEdit: ListItemCategoryInternalModel) => void;
}

export const CategorySelection = (props: CategorySelectionProps) => {
  const [searchInput, setSearchInput] = useState("");
  const [searchedCategories, setSearchedCategories] = useState<
    ListItemCategoryInternalModel[]
  >([]);

  useEffect(() => {
    if (props.reset) {
      setSearchInput("");
    }
  }, [props.reset]);

  useEffect(() => {
    const searchValue = normalizeText(searchInput);
    const newSearchedCategories = props.categories
      .filter((c) => normalizeText(c.name).includes(searchValue))
      .sort(
        (a, b) =>
          a.name.indexOf(searchValue) - b.name.indexOf(searchValue) ||
          getDateValue(b.created) - getDateValue(a.created),
      );
    setSearchedCategories(newSearchedCategories);
  }, [props.categories, searchInput]);

  return (
    <Box className="category-selection">
      <CurtainPopupHeading
        title="Выбор категории"
        onCancel={props.onCancel}
        onConfirm={props.onCancel}
      />
      <BaseInput
        className="category-selection-input"
        placeholder="Поиск по категориям"
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value.slice(0, 200))}
        startAdornment={
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        }
      />
      <Button
        className="category-selection-add-button"
        variant="text"
        color="primary"
        startIcon={<AddOutlinedIcon />}
        onClick={props.onCreateCategoryClick}
      >
        Создать категорию
      </Button>
      <CategoriesList
        categories={searchedCategories}
        selectedCategory={props.currentListItem?.localCategory ?? null}
        onCategoryClick={props.onSelect}
        onEditCategoryClick={props.onEditCategoryClick}
      />
    </Box>
  );
};
