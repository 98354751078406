import { Typography } from "@mui/material";
import React, { ReactNode } from "react";
import "./InputLabel.scss";

export interface InputLabelProps {
  children: ReactNode;
}

export const InputLabel = (props: InputLabelProps) => {
  return <Typography className="input-label">{props.children}</Typography>;
};
